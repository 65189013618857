import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import { BlogLayout } from "../../components/BlogLayout"

class SparkProject extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Spark" />
        <BlogLayout>
          <div className="blog-title">Spark</div>

          <p className="subtitle">
            A decentralized, federated private tracker network that makes it
            easy to create your own community to share content with others.
          </p>

          <div className="tech-stack">
            {technicalStack.map(t => (
              <span className="item">{t}</span>
            ))}
          </div>

          <hr />
        </BlogLayout>
      </Layout>
    )
  }
}

export default SparkProject

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const technicalStack = [
  "Go",
  "Apache Cassandra",
  "Redis",
  "Websocket",
  "BitTorrent",
]
